import React from 'react';
import { Link } from 'react-router-dom';
import './Navigation.css';

function Navigation() {
  return (
    <nav className="navigation">
      <div className="nav-content">
        <Link to="/" className="nav-logo">
          Botique.ai
        </Link>
        <div className="nav-links">
          <Link to="/" className="nav-link">Home</Link>
          <Link to="/my-bots" className="nav-link">My Bots</Link>
        </div>
      </div>
    </nav>
  );
}

export default Navigation;
