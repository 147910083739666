import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Tutorial.css';

function Tutorial() {
  const [currentStep, setCurrentStep] = useState(0);

  const steps = [
    {
      title: "Welcome to Botique.ai",
      content: "Botique.ai is your platform for discovering, deploying, and interacting with AI-powered bots. Let's walk through how to use our platform."
    },
    {
      title: "Finding a Bot",
      content: "Use the 'Find a Bot' section to search for existing bots. Enter keywords or bot names in the search bar to find bots that match your needs."
    },
    {
      title: "Sharing a Bot",
      content: "Use the 'Upload Your Own Bot' section to upload your bots to Botique.ai with description. So others could find it with search."
    },
    {
      title: "Deploying from Templates",
      content: (
        <>
          In the 'Deploy a Bot' section, you can choose from our curated bot templates. Click on a template to view details and deploy your own instance of the bot. 
          <Link to="/tutorial/deploy-from-templates" className="detailed-tutorial-link">View detailed tutorial</Link>
        </>
      )
    },
    {
      title: "Deploying Hugging Face Models",
      content: (
        <>
          You can also deploy bots using Hugging Face models. Enter a model name or choose from popular models to create a bot with state-of-the-art AI capabilities.
          <Link to="/tutorial/deploy-huggingface" className="detailed-tutorial-link">View detailed tutorial</Link>
        </>
      )
    }
  ];

  const nextStep = () => {
    setCurrentStep((prev) => Math.min(prev + 1, steps.length - 1));
  };

  const prevStep = () => {
    setCurrentStep((prev) => Math.max(prev - 1, 0));
  };

  return (
    <div className="tutorial">
      <h2>How to Use Botique.ai</h2>
      <div className="tutorial-content">
        <h3>{steps[currentStep].title}</h3>
        <p>{steps[currentStep].content}</p>
      </div>
      <div className="tutorial-navigation">
        <div className="tutorial-navigation-controls">
          <span className="tutorial-step-indicator">{currentStep + 1}/{steps.length}</span>
          <button onClick={prevStep} disabled={currentStep === 0}>
            Previous
          </button>
          <button onClick={nextStep} disabled={currentStep === steps.length - 1}>
            {currentStep === steps.length - 1 ? 'Finish' : 'Next'}
          </button>
        </div>
      </div>
    </div>
  );
}

export default Tutorial;
