import React from 'react';
import './LegalNotice.css';

function LegalNotice() {
  return (
    <div className="legal-notice">
      <h2>Legal Notice</h2>
      <p>
        Botique.ai is currently a non-profit project in development. This platform is provided "as is" and "as available" without any representations or warranties, express or implied.
      </p>
      <p>
        The individuals involved in creating, developing, and maintaining Botique.ai shall not be liable for any direct, indirect, incidental, special, consequential or exemplary damages resulting from your use of this service.
      </p>
      <p>
        By using Botique.ai, you agree to these terms. As this project evolves, additional terms may be added. We recommend checking this notice periodically for any updates.
      </p>
      <p>
        While Botique.ai is currently a collaborative effort by individuals passionate about AI and bot technology, it may evolve into a formal company structure in the future. Any such changes will be reflected in updated terms and notices on this site.
      </p>
      <p>
        &copy; {new Date().getFullYear()} Botique.ai Project. All rights reserved.
      </p>
    </div>
  );
}

export default LegalNotice;
