import React, { useState } from 'react';
import { uploadUserBot } from '../../services/api';
import './BotUploadForm.css';

function BotUploadForm({ onUploadSuccess }) {
  const [userId, setUserId] = useState('test_user');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [platformBotName, setPlatformBotName] = useState('');
  const [platform, setPlatform] = useState('telegram');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');

    try {
      await uploadUserBot(userId,name, description, platform, platformBotName);
      setUserId('test_user');
      setName('');
      setDescription('');
      setPlatform('');
      setPlatformBotName('');
      onUploadSuccess();
    } catch (err) {
      setError('Failed to upload bot. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="bot-upload-form">
      <h2>Upload a New Bot</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="botName">Bot Name:</label>
          <input
            type="text"
            id="botName"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
          <p className="helper-text">This name will be displayed on the platform.</p>
        </div>
        <div className="form-group">
          <label htmlFor="botDescription">Description:</label>
          <textarea
            id="botDescription"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
          />
          <p className="helper-text">Provide a brief description of your bot's functionality.</p>
        </div>
        <div className="form-group">
          <label htmlFor="platformBotName">
            {platform === 'telegram' ? 'Telegram' : 'Discord'} Bot Name:
          </label>
          <input
            type="text"
            id="platformBotName"
            value={platformBotName}
            onChange={(e) => setPlatformBotName(e.target.value)}
            required
          />
          <p className="helper-text">
            {platform === 'telegram' 
              ? "Enter the bot's username on Telegram (without the '@' symbol)."
              : "Enter the bot's username on Discord (without the '#' discriminator)."}
          </p>
        </div>
        <div className="form-group">
          <label>Platform:</label>
          <div className="platform-toggle">
            <button
              type="button"
              className={`platform-button ${platform === 'telegram' ? 'active' : ''}`}
              onClick={() => setPlatform('telegram')}
            >
              Telegram
            </button>
            <button
              type="button"
              className={`platform-button ${platform === 'discord' ? 'active' : ''}`}
              onClick={() => setPlatform('discord')}
            >
              Discord
            </button>
          </div>
        </div>
        <button type="submit" disabled={isLoading}>
          {isLoading ? 'Uploading...' : 'Upload Bot'}
        </button>
      </form>
      {error && <p className="error-message">{error}</p>}
    </div>
  );
}

export default BotUploadForm;
