import React, { useState } from 'react';
import { searchBots } from '../services/api';
import './BotSearch.css';

function BotSearch({ onSearchResults }) {
  const [query, setQuery] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSearch = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const results = await searchBots(query);
      onSearchResults(results);
    } catch (error) {
      console.error('Error searching bots:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={handleSearch} className="bot-search">
      <input
        type="text"
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        placeholder="Search for bots..."
        className="search-input"
      />
      <button type="submit" disabled={isLoading} className="search-button">
        {isLoading ? 'Searching...' : 'Search'}
      </button>
    </form>
  );
}

export default BotSearch;
