import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './DeployHuggingFaceModel.css';
import {  stopBot, stopTelegramBot, deployHuggingfaceBot } from '../services/api';
import { FaDiscord } from "react-icons/fa";
import { FaTelegram } from "react-icons/fa";

function DeployHuggingFacePage() {
  const { modelName } = useParams();
  const navigate = useNavigate();
  const [token, setToken] = useState('');
  const [platform, setPlatform] = useState('telegram');
  const [isDeployed, setIsDeployed] = useState(false);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchModelDetails = async () => {
      try {
        setIsLoading(true);
      } catch (err) {
        console.error('Error fetching model details:', err);
        setError('Failed to load model details');
      } finally {
        setIsLoading(false);
      }
    };

    fetchModelDetails();
  }, [modelName]);

  const handleDeploy = async (e) => {
    e.preventDefault();
    try {
      await deployHuggingfaceBot(modelName, token, platform);
      setIsDeployed(true);
      alert('Bot deployed successfully! Please wait 15-30 seconds for the bot to start.');
    } catch (err) {
      setError('Failed to deploy bot');
      console.error(err);
    }
  };

  const handleStop = async () => {
    try {
      await stopBot(token);
      setIsDeployed(false);
      alert('Bot stopped successfully!');
    } catch (err) {
      setError('Failed to stop bot');
      console.error(err);
    }
  };

  return (
    <div className="deploy-huggingface-model">
      <h1>Deploy Hugging Face Model</h1>
      <div className="model-info">
        <strong>Model:</strong> {modelName}
      </div>
      
      {isDeployed ? (
        <div className="bot-management-section">
          <div className="bot-status">
            <h2>Bot Status: Running</h2>
            <p>Your bot has been successfully deployed and is running.</p>
          </div>
          
          <div className="bot-actions">
            <button 
              onClick={handleStop}
              className="action-button stop-button"
            >
              Stop Bot
            </button>
            <button 
              onClick={() => setIsDeployed(false)}
              className="action-button"
            >
              Deploy Another Bot
            </button>
          </div>
        </div>
      ) : (
        <form onSubmit={handleDeploy} className="deploy-form">
          <div className="platform-selector">
            <label>Select Platform:</label>
            <div className="platform-buttons">
              <button
                type="button"
                className={`platform-btn ${platform === 'telegram' ? 'active' : 'inactive'}`}
                onClick={() => setPlatform('telegram')}
              >
                <FaTelegram className="platform-icon" />
                <span className="platform-name">Telegram</span>
              </button>
              <button
                type="button"
                className={`platform-btn ${platform === 'discord' ? 'active' : 'inactive'}`}
                onClick={() => setPlatform('discord')}
              >
                <FaDiscord className="platform-icon" />
                <span className="platform-name">Discord</span>
              </button>
            </div>
          </div>

          <div className="form-group">
            <label htmlFor="token">
              {platform === 'telegram' ? 'Telegram Bot Token' : 'Discord Bot Token'}:
            </label>
            <input
              type="text"
              id="token"
              value={token}
              onChange={(e) => setToken(e.target.value)}
              placeholder={`Enter your ${platform} bot token`}
              required
            />
            <small className="help-text">
              {platform === 'telegram' 
                ? 'Get your token from BotFather on Telegram'
                : 'Get your token from Discord Developer Portal'}
            </small>
          </div>

          {error && <div className="error-message">{error}</div>}
          
          <button type="submit" className="action-button deploy-button">
            Deploy Bot
          </button>
        </form>
      )}
    </div>
  );
}

export default DeployHuggingFacePage;
