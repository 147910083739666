import React, { useState, useEffect } from 'react';
import { useParams, Link, useLocation } from 'react-router-dom';
import { getBotDetails } from '../services/api';
import './BotDetailsPage.css';

function BotDetailsPage() {
  const { botUsername, telegramAccount, discordAccount } = useParams();
  const location = useLocation();
  
  const isTelegram = location.pathname.includes('/external_bots/telegram/');
  const isDiscord = location.pathname.includes('/external_bots/discord/');
  
  const botIdentifier = (telegramAccount || discordAccount || botUsername || '')
    .replace(/^@/, '');

  const [bot, setBot] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function fetchBotDetails() {
      try {
        const botDetails = await getBotDetails(botIdentifier);
        setBot(botDetails);
      } catch (err) {
        setError(`Failed to fetch bot details: ${err.message}`);
      } finally {
        setIsLoading(false);
      }
    }

    if (botIdentifier) {
      fetchBotDetails();
    } else {
      setError('No bot identifier provided');
      setIsLoading(false);
    }
  }, [botIdentifier]);

  if (isLoading) return <div className="loading">Loading...</div>;
  if (error) return <div className="error">{error}</div>;
  if (!bot) return <div className="not-found">Bot not found</div>;

  const getExternalLink = () => {
    if (isTelegram) {
      return {
        url: `https://t.me/${botIdentifier}`,
        text: 'Open in Telegram',
        className: 'telegram-link'
      };
    }
    if (isDiscord && bot.url) {
      return {
        url: bot.url,
        text: 'Open in top.gg',
        className: 'discord-link'
      };
    }
    return null;
  };

  const externalLink = getExternalLink();

  return (
    <div className="bot-details-page">
      <Link to="/bots" className="back-link">← Back to Bots</Link>
      
      <div className="bot-info">
        <h1>{bot.name}</h1>
        {bot.description && (
          <p className="description">{bot.description}</p>
        )}
        <p className="username">
          {isTelegram ? `@${botIdentifier}` : bot.username || bot.name}
        </p>
      </div>

      {externalLink && (
        <div className="bot-actions">
          <a 
            href={externalLink.url} 
            target="_blank" 
            rel="noopener noreferrer" 
            className={externalLink.className}
          >
            {externalLink.text}
          </a>
        </div>
      )}
    </div>
  );
}

export default BotDetailsPage;