import React from 'react';
import { Link } from 'react-router-dom';
import './DeployHuggingFaceTutorial.css';

function DeployHuggingFaceTutorial() {
  return (
    <div className="deploy-huggingface-tutorial">
      <header>
        <h1>Guide to Deploying Hugging Face Models as Bots</h1>
      </header>

      <main>
        <section>
          <h2>1. Accessing and Choosing a Hugging Face Model</h2>
          <p>
            Go to the Botique.ai homepage and look for the "Deploy a Bot" section. Find the "From 🤗Hugging Face" option. 
            You can either enter a specific Hugging Face model name or choose from the list of popular models provided. 
            For example, you might want to use the "meta-llama/Llama-3.1-8B-Instruct" model.
          </p>
          <img src="/images/huggingface-choose-model.png" alt="Accessing and Choosing a Hugging Face Model" className="tutorial-image" />
        </section>

        <section>
          <h2>2. Configuring and Deploying Your Bot</h2>
          <p>After selecting a model, you'll need to configure and deploy your bot:</p>
          <ol>
            <li>Choose the platform (Telegram or Discord)</li>
            <li>Enter your bot token</li>
            <li>Select the model task (if not automatically inferred)</li>
            <li>Click the "Deploy Model" button</li>
          </ol>
          <p>Our system will handle the deployment process, connecting your chosen model to the selected platform. For example, if you chose the "meta-llama/Llama-3.1-8B-Instruct" model, you'll be able to chat with it on Telegram. Use "Chat Completion" task for this model.</p>
          <img src="/images/configure-deploy-bot.png" alt="Configuring and Deploying the Bot" className="tutorial-image" />
        </section>

        <section>
          <h2>3. Interacting with Your Bot</h2>
          <p>After successful deployment, you can start interacting with your bot on the chosen platform. The bot's capabilities will depend on the Hugging Face model you selected. For example, if we deployed the "meta-llama/Llama-3.1-8B-Instruct" model, you can ask it questions, generate text, or even create images.</p>
          <img src="/images/interact-bot-1.png" alt="Interacting with the Bot" className="tutorial-image" />
          <p>If you instead choose to deploy the "stabilityai/stable-diffusion-xl-base-1.0" model in step 2 with text-to-image task, you can generate images based on text prompts.</p>
          <img src="/images/interact-bot-2.png" alt="Interacting with the Bot" className="tutorial-image" />
        </section>
      </main>

      <footer>
        <Link to="/" className="back-to-home">Back to Home</Link>
      </footer>
    </div>
  );
}

export default DeployHuggingFaceTutorial;
