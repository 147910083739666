import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getBotTemplates } from '../services/api';
import './BotTemplateList.css';

function BotTemplateList() {
  const [templates, setTemplates] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function fetchTemplates() {
      try {
        const data = await getBotTemplates();
        setTemplates(data);
      } catch (err) {
        setError('Failed to fetch bot templates');
      }
    }
    fetchTemplates();
  }, []);

  if (error) return <div className="error">{error}</div>;
  if (templates.length === 0) return <div>Loading templates...</div>;

  return (
    <div className="bot-template-list">
      <h2>From Bot Templates</h2>
      <div className="template-grid">
        {templates.map((template) => (
          <Link to={`/bot-templates/${template.id}`} key={template.id} className="template-item">
            <h3>{template.name}</h3>
            <p>{template.description}</p>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default BotTemplateList;
