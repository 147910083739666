import React from 'react';
import { Link } from 'react-router-dom';
import './DeployFromTemplatesTutorial.css';

function DeployFromTemplatesTutorial() {
  return (
    <div className="deploy-templates-tutorial">
      <header>
        <h1>Guide to Deploying Bots from Templates</h1>
      </header>

      <main>
        <section>
          <h2>1. Finding the Bot Templates</h2>
          <p>Go to the Botique.ai homepage and look for the "Deploy a Bot" section. This is where you'll find our bot templates.</p>
        </section>

        <section>
          <h2>2. Picking a Template</h2>
          <p>Browse through the different bot templates. Each one has different capabilities. Read the descriptions and choose one that fits your needs. Click on the template you like to get more information.</p>
          <img src="/images/choose-template.png" alt="Choosing a Template" className="tutorial-image" />
        </section>

        <section>
          <h2>3. Understanding the Template</h2>
          <p>On the template page, you'll see details about what the bot can do and what information you need to set it up. You'll need a "bot token" to proceed - we'll get that in the next step.</p>
          <img src="/images/template-details.png" alt="Template Details Page" className="tutorial-image" />
        </section>

        <section>
          <h2>4. Getting Your Bot Token</h2>
          <p>To set up your bot, you need a special code called a "bot token". Here's how to get it:</p>
          <ol>
            <li>Open Telegram and search for "@BotFather"</li>
            <li>Start a chat with BotFather and type "/newbot"</li>
            <li>Follow the instructions to name your bot</li>
            <li>BotFather will give you a token - save this, you'll need it soon!</li>
          </ol>
          <img src="/images/botfather-token.png" alt="Getting Token from BotFather" className="tutorial-image" />
        </section>

        <section>
          <h2>5. Setting Up Your Bot</h2>
          <p>Now, let's set up your bot:</p>
          <ol>
            <li>Go back to the template page on Botique.ai</li>
            <li>Fill in any information the template asks for</li>
            <li>Paste in the bot token you got from BotFather</li>
            <li>Click the "Deploy Bot" button to create your bot</li>
          </ol>
          <img src="/images/deploy-configuration.png" alt="Deploying the Bot" className="tutorial-image" />
        </section>

        <section>
          <h2>6. Your Bot is Ready!</h2>
          <p>Great job! Your bot is now set up. You'll see a message confirming this, along with a link to your new bot on Telegram.</p>
        </section>

        <section>
          <h2>7. Talking to Your Bot</h2>
          <p>Click the link to open your bot in Telegram. You can now start chatting with your bot! Try out its features and see what it can do.</p>
          <img src="/images/bot-interaction.png" alt="Interacting with the Bot" className="tutorial-image" />
        </section>
      </main>

      <footer>
        <Link to="/" className="back-to-home">Back to Home</Link>
      </footer>
    </div>
  );
}

export default DeployFromTemplatesTutorial;
