import React from 'react';
import { Link } from 'react-router-dom';
import { FaPaperPlane, FaDiscord } from 'react-icons/fa';
import './BotList.css';

const renderBotSection = (title, botArray, type) => {
  const getDetailsLink = (bot) => {
    console.log('Bot data:', {
      type,
      bot,
      name: bot.name,
      username: bot.username,
      url: bot.url
    });

    switch (type) {
      case 'telegram':
        const cleanUsername = (bot.username || bot.name).replace(/^@/, '');
        console.log('Telegram link generated:', cleanUsername);
        return `/external_bots/telegram/${cleanUsername}`;
      case 'discord':
        console.log('Discord bot properties:', {
          name: Array.isArray(bot.name) ? bot.name[0] : bot.name,
          username: bot.username,
          url: bot.url
        });
        // Use first element if name is an array
        const discordIdentifier = Array.isArray(bot.name) ? bot.name[0] : bot.name;
        console.log('Discord link generated:', discordIdentifier);
        return `/external_bots/discord/${discordIdentifier}`;
      case 'botique':
        return `/bots/${bot.id}`;
      default:
        return `/bots/${bot.id}`;
    }
  };

  return (
    <div className="bot-section">
      <h2 className="subsection-title">{title}</h2>
      {botArray && botArray.length > 0 ? (
        <ul className="bot-grid">
          {botArray.map((bot, index) => {
            console.log(`Rendering bot ${index}:`, bot);
            return (
              <li key={`${title}-${index}`} className="bot-item">
                <h3>{bot.name || 'Unnamed Bot'}</h3>
                <p className="bot-username">
                  {type === 'telegram' ? `@${bot.username || bot.name}` : bot.username}
                </p>
                <Link to={getDetailsLink(bot)} className="bot-link">
                  {type === 'discord' ? <FaDiscord /> : <FaPaperPlane />} View Details
                </Link>
              </li>
            );
          })}
        </ul>
      ) : (
        <p>No bots found in this category.</p>
      )}
    </div>
  );
};

function BotList({ bots }) {
  return (
    <div className="bot-list">
      {renderBotSection("Bots from Botique.ai", bots.botique, "botique")}
      {renderBotSection("Other Telegram Bots", bots.telegram, "telegram")}
      {renderBotSection("Other Discord Bots", bots.discord, "discord")}
    </div>
  );
}

export default BotList;